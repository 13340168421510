import { z } from 'zod';
import { FieldSchema, makeFieldSchema } from '@backstage/plugin-scaffolder-react';

export const AppShortNamePickerSchema: FieldSchema<{ value: string }, { getOwnApps?: boolean }> = makeFieldSchema({
  output: schema =>
    schema.object({
      value: z.string(),
    }),
  uiOptions: schema =>
    schema.object({ getOwnApps: z.boolean().optional().describe('allows to only get employee specific apps') }),
});
