import color from '../primitives/color';

export const dark = {
  bg: {
    page: {
      main: color.gray[0],
      alt: color.gray[10],
    },
    surface: {
      main: color.gray[0],
      alt: color.gray[10],
      reserve: color.gray[20],
      bold: {
        brandBlue: color.blue[80],
        brandRed: color.red[80],
      },
      subtle: {
        brandBlue: color.blue[20],
      },
      hint: {
        brandBlue: color.blue[10],
      },
    },
    fill: {
      main: color.gray[0],
      alt: color.gray[10],
      reserve: color.gray[20],
      hover: color.blue[30],
      active: color.blue[40],
      grayHover: color.gray[30],
      grayActive: color.gray[40],
      interactive: color.blue[80],
      interactiveHover: color.blue[90],
      interactiveActive: color.blue[100],
      interactiveRed: color.red[80],
      interactiveRedHover: color.red[90],
      interactiveRedActive: color.red[100],
      disabled: color.gray[30],
      readOnly: color.gray[30],
      autoFill: color.blue[20],
      bold: {
        selected: color.blue[80],
        graySelected: color.gray[80],
        gray: color.gray[80],
        grayHover: color.gray[90],
        grayActive: color.gray[100],
        blue: color.blue[80],
        blueHover: color.blue[90],
        blueActive: color.blue[100],
        red: color.red[80],
        redHover: color.red[90],
        redActive: color.red[100],
        green: color.green[80],
        greenHover: color.green[90],
        greenActive: color.green[100],
        orange: color.orange[80],
        orangeHover: color.orange[90],
        orangeActive: color.orange[100],
        lightOrange: color.lightOrange[80],
        lightOrangeHover: color.lightOrange[90],
        lightOrangeActive: color.lightOrange[100],
        magenta: color.magenta[80],
        magentaHover: color.magenta[90],
        magentaActive: color.magenta[100],
        purple: color.purple[80],
        purpleHover: color.purple[90],
        purpleActive: color.purple[100],
      },
      subtle: {
        selected: color.blue[20],
        graySelected: color.gray[20],
        gray: color.gray[50],
        grayHover: color.gray[60],
        grayActive: color.gray[70],
        blue: color.blue[50],
        blueHover: color.blue[60],
        blueActive: color.blue[70],
        red: color.red[50],
        redHover: color.red[60],
        redActive: color.red[70],
        green: color.green[50],
        greenHover: color.green[60],
        greenActive: color.green[70],
        orange: color.orange[50],
        orangeHover: color.orange[60],
        orangeActive: color.orange[70],
        lightOrange: color.lightOrange[50],
        lightOrangeHover: color.lightOrange[60],
        lightOrangeActive: color.lightOrange[70],
        magenta: color.magenta[50],
        magentaHover: color.magenta[60],
        magentaActive: color.magenta[70],
        purple: color.purple[50],
        purpleHover: color.purple[60],
        purpleActive: color.purple[70],
      },
      neutral: {
        bold: color.gray[80],
        subtle: color.gray[50],
        hint: color.gray[30],
      },
      info: {
        bold: color.blue[80],
        subtle: color.blue[50],
        hint: color.blue[30],
      },
      error: {
        bold: color.red[80],
        subtle: color.red[50],
        hint: color.red[30],
      },
      success: {
        bold: color.green[80],
        subtle: color.green[50],
        hint: color.green[30],
      },
      warning: {
        bold: color.orange[80],
        subtle: color.orange[50],
        hint: color.orange[30],
      },
      hint: {
        gray: color.gray[30],
        grayHover: color.gray[40],
        grayActive: color.gray[50],
        blue: color.blue[30],
        blueHover: color.blue[40],
        blueActive: color.blue[50],
        red: color.red[30],
        redHover: color.red[40],
        redActive: color.red[50],
        green: color.green[30],
        greenHover: color.green[40],
        greenActive: color.green[50],
        orange: color.orange[30],
        orangeHover: color.orange[40],
        orangeActive: color.orange[50],
        lightOrange: color.lightOrange[30],
        lightOrangeHover: color.lightOrange[40],
        lightOrangeActive: color.lightOrange[50],
        magenta: color.magenta[30],
        magentaHover: color.magenta[40],
        magentaActive: color.magenta[50],
        purple: color.purple[30],
        purpleHover: color.purple[40],
        purpleActive: color.purple[50],
      },
    },
  },
  text: {
    primary: color.gray[130],
    secondary: color.gray[80],
    disabled: color.gray[50],
    inverse: color.gray[10],
    interactive: color.blue[80],
    info: color.blue[80],
    success: color.green[80],
    warning: color.orange[80],
    error: color.red[80],
    gray: color.gray[80],
    blue: color.blue[80],
    red: color.red[80],
    green: color.green[80],
    orange: color.orange[80],
    lightOrange: color.lightOrange[80],
    magenta: color.magenta[80],
    purple: color.purple[80],
    on: {
      fill: {
        subtle: {
          gray: color.gray[120],
          blue: color.blue[120],
          red: color.red[120],
          green: color.green[120],
          orange: color.orange[120],
          lightOrange: color.lightOrange[120],
          magenta: color.magenta[120],
          purple: color.purple[120],
        },
        hint: {
          gray: color.gray[90],
          blue: color.blue[90],
          red: color.red[90],
          green: color.green[90],
          orange: color.orange[90],
          lightOrange: color.lightOrange[90],
          magenta: color.magenta[90],
          purple: color.purple[90],
        },
      },
    },
  },
  border: {
    primary: color.gray[70],
    secondary: color.gray[50],
    tertiary: color.gray[40],
    inverse: color.gray[10],
    disabled: color.gray[40],
    interactive: color.blue[80],
    selected: color.blue[70],
    info: color.blue[80],
    error: color.red[80],
    success: color.green[80],
    warning: color.orange[80],
    bold: {
      gray: color.gray[80],
      blue: color.blue[80],
      red: color.red[80],
      green: color.green[80],
      orange: color.orange[80],
      lightOrange: color.lightOrange[80],
      magenta: color.magenta[80],
      purple: color.purple[80],
    },
    subtle: {
      gray: color.gray[50],
      blue: color.blue[50],
      red: color.red[50],
      green: color.green[50],
      orange: color.orange[50],
      lightOrange: color.lightOrange[50],
      magenta: color.magenta[50],
      purple: color.purple[50],
    },
  },
  icon: {
    primary: color.gray[130],
    secondary: color.gray[80],
    disabled: color.gray[50],
    inverse: color.gray[10],
    interactive: color.blue[80],
    info: color.blue[80],
    success: color.green[80],
    warning: color.orange[80],
    error: color.red[80],
    gray: color.gray[80],
    blue: color.blue[80],
    red: color.red[80],
    green: color.green[80],
    orange: color.orange[80],
    lightOrange: color.lightOrange[80],
    magenta: color.magenta[80],
    purple: color.purple[80],
    on: {
      fill: {
        subtle: {
          gray: color.gray[120],
          blue: color.blue[120],
          red: color.red[120],
          green: color.green[120],
          orange: color.orange[120],
          lightOrange: color.lightOrange[120],
          magenta: color.magenta[120],
          purple: color.purple[120],
        },
        hint: {
          gray: color.gray[90],
          blue: color.blue[90],
          red: color.red[90],
          green: color.green[90],
          orange: color.orange[90],
          lightOrange: color.lightOrange[90],
          magenta: color.magenta[90],
          purple: color.purple[90],
        },
      },
    },
  },
  outline: {
    hover: color.blue[40],
    active: color.blue[50],
    focus: color.blue[70],
    focusAlt: color.gray[140],
  },
};
