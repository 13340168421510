import color from '../primitives/color';

export const light = {
  bg: {
    page: {
      main: color.gray[140],
      alt: color.gray[130],
    },
    surface: {
      main: color.gray[140],
      alt: color.gray[130],
      reserve: color.gray[120],
      bold: {
        brandBlue: color.blue[60],
        brandRed: color.red[60],
      },
      subtle: {
        brandBlue: color.blue[120],
      },
      hint: {
        brandBlue: color.blue[130],
      },
    },
    fill: {
      main: color.gray[140],
      alt: color.gray[130],
      reserve: color.gray[120],
      hover: color.blue[110],
      active: color.blue[100],
      grayHover: color.gray[110],
      grayActive: color.gray[100],
      interactive: color.blue[60],
      interactiveHover: color.blue[50],
      interactiveActive: color.blue[40],
      interactiveRed: color.red[60],
      interactiveRedHover: color.red[50],
      interactiveRedActive: color.red[40],
      disabled: color.gray[110],
      readOnly: color.gray[110],
      autoFill: color.blue[120],
      bold: {
        selected: color.blue[60],
        graySelected: color.gray[60],
        gray: color.gray[60],
        grayHover: color.gray[50],
        grayActive: color.gray[40],
        blue: color.blue[60],
        blueHover: color.blue[50],
        blueActive: color.blue[40],
        red: color.red[60],
        redHover: color.red[50],
        redActive: color.red[40],
        green: color.green[60],
        greenHover: color.green[50],
        greenActive: color.green[40],
        orange: color.orange[60],
        orangeHover: color.orange[50],
        orangeActive: color.orange[40],
        lightOrange: color.lightOrange[60],
        lightOrangeHover: color.lightOrange[50],
        lightOrangeActive: color.lightOrange[40],
        magenta: color.magenta[60],
        magentaHover: color.magenta[50],
        magentaActive: color.magenta[40],
        purple: color.purple[60],
        purpleHover: color.purple[50],
        purpleActive: color.purple[40],
      },
      subtle: {
        selected: color.blue[120],
        graySelected: color.gray[120],
        gray: color.gray[90],
        grayHover: color.gray[80],
        grayActive: color.gray[70],
        blue: color.blue[90],
        blueHover: color.blue[80],
        blueActive: color.blue[70],
        red: color.red[90],
        redHover: color.red[80],
        redActive: color.red[70],
        green: color.green[90],
        greenHover: color.green[80],
        greenActive: color.green[70],
        orange: color.orange[90],
        orangeHover: color.orange[80],
        orangeActive: color.orange[70],
        lightOrange: color.lightOrange[90],
        lightOrangeHover: color.lightOrange[80],
        lightOrangeActive: color.lightOrange[70],
        magenta: color.magenta[90],
        magentaHover: color.magenta[80],
        magentaActive: color.magenta[70],
        purple: color.purple[90],
        purpleHover: color.purple[80],
        purpleActive: color.purple[70],
      },
      neutral: {
        bold: color.gray[60],
        subtle: color.gray[90],
        hint: color.gray[110],
      },
      info: {
        bold: color.blue[60],
        subtle: color.blue[90],
        hint: color.blue[110],
      },
      error: {
        bold: color.red[60],
        subtle: color.red[90],
        hint: color.red[110],
      },
      success: {
        bold: color.green[60],
        subtle: color.green[90],
        hint: color.green[110],
      },
      warning: {
        bold: color.orange[60],
        subtle: color.orange[90],
        hint: color.orange[110],
      },
      hint: {
        gray: color.gray[110],
        grayHover: color.gray[100],
        grayActive: color.gray[90],
        blue: color.blue[110],
        blueHover: color.blue[100],
        blueActive: color.blue[90],
        red: color.red[110],
        redHover: color.red[100],
        redActive: color.red[90],
        green: color.green[110],
        greenHover: color.green[100],
        greenActive: color.green[90],
        orange: color.orange[110],
        orangeHover: color.orange[100],
        orangeActive: color.orange[90],
        lightOrange: color.lightOrange[110],
        lightOrangeHover: color.lightOrange[100],
        lightOrangeActive: color.lightOrange[90],
        magenta: color.magenta[110],
        magentaHover: color.magenta[100],
        magentaActive: color.magenta[90],
        purple: color.purple[110],
        purpleHover: color.purple[100],
        purpleActive: color.purple[90],
      },
    },
  },
  text: {
    primary: color.gray[10],
    secondary: color.gray[60],
    disabled: color.gray[90],
    inverse: color.gray[130],
    interactive: color.blue[60],
    info: color.blue[60],
    success: color.green[60],
    warning: color.orange[60],
    error: color.red[60],
    gray: color.gray[60],
    blue: color.blue[60],
    red: color.red[60],
    green: color.green[60],
    orange: color.orange[60],
    lightOrange: color.lightOrange[60],
    magenta: color.magenta[60],
    purple: color.purple[60],
    on: {
      fill: {
        subtle: {
          gray: color.gray[20],
          blue: color.blue[20],
          red: color.red[20],
          green: color.green[20],
          orange: color.orange[20],
          lightOrange: color.lightOrange[20],
          magenta: color.magenta[20],
          purple: color.purple[20],
        },
        hint: {
          gray: color.gray[50],
          blue: color.blue[50],
          red: color.red[50],
          green: color.green[50],
          orange: color.orange[50],
          lightOrange: color.lightOrange[50],
          magenta: color.magenta[50],
          purple: color.purple[50],
        },
      },
    },
  },
  border: {
    primary: color.gray[70],
    secondary: color.gray[90],
    tertiary: color.gray[100],
    inverse: color.gray[130],
    disabled: color.gray[100],
    interactive: color.blue[60],
    selected: color.blue[70],
    info: color.blue[60],
    error: color.red[60],
    success: color.green[60],
    warning: color.orange[60],
    bold: {
      gray: color.gray[60],
      blue: color.blue[60],
      red: color.red[60],
      green: color.green[60],
      orange: color.orange[60],
      lightOrange: color.lightOrange[60],
      magenta: color.magenta[60],
      purple: color.purple[60],
    },
    subtle: {
      gray: color.gray[90],
      blue: color.blue[90],
      red: color.red[90],
      green: color.green[90],
      orange: color.orange[90],
      lightOrange: color.lightOrange[90],
      magenta: color.magenta[90],
      purple: color.purple[90],
    },
  },
  icon: {
    primary: color.gray[10],
    secondary: color.gray[60],
    disabled: color.gray[90],
    inverse: color.gray[130],
    interactive: color.blue[60],
    info: color.blue[60],
    success: color.green[60],
    warning: color.orange[60],
    error: color.red[60],
    gray: color.gray[60],
    blue: color.blue[60],
    red: color.red[60],
    green: color.green[60],
    orange: color.orange[60],
    lightOrange: color.lightOrange[60],
    magenta: color.magenta[60],
    purple: color.purple[60],
    on: {
      fill: {
        subtle: {
          gray: color.gray[20],
          blue: color.blue[20],
          red: color.red[20],
          green: color.green[20],
          orange: color.orange[20],
          lightOrange: color.lightOrange[20],
          magenta: color.magenta[20],
          purple: color.purple[20],
        },
        hint: {
          gray: color.gray[50],
          blue: color.blue[50],
          red: color.red[50],
          green: color.green[50],
          orange: color.orange[50],
          lightOrange: color.lightOrange[50],
          magenta: color.magenta[50],
          purple: color.purple[50],
        },
      },
    },
  },
  outline: {
    hover: color.blue[100],
    active: color.blue[90],
    focus: color.blue[70],
    focusAlt: color.gray[0],
  },
};
