const color = {
  gray: {
    '0': '#131313',
    '10': '#1B1F23',
    '20': '#232B33',
    '30': '#2E3D4A',
    '40': '#3C4E5F',
    '50': '#4D5E6C',
    '60': '#5E6D7A',
    '70': '#869199',
    '80': '#9DA6AB',
    '90': '#D0DAE0',
    '100': '#E6ECEF',
    '110': '#EDF1F3',
    '120': '#F3F5F7',
    '130': '#F9FAFB',
    '140': '#FFFFFF',
  },
  blue: {
    '0': '#00172A',
    '10': '#002340',
    '20': '#002F55',
    '30': '#003A6A',
    '40': '#004C88',
    '50': '#005CA4',
    '60': '#006DC0',
    '70': '#0090FB',
    '80': '#3AABFF',
    '90': '#ACDCFF',
    '100': '#D6EDFF',
    '110': '#E0F2FF',
    '120': '#EAF6FF',
    '130': '#F5FBFF',
    '140': '#FFFFFF',
  },
  red: {
    '0': '#390007',
    '10': '#50000A',
    '20': '#67000D',
    '30': '#73000F',
    '40': '#950013',
    '50': '#B80018',
    '60': '#D6001B',
    '70': '#FF4A61',
    '80': '#FF798A',
    '90': '#FFC6CD',
    '100': '#FFD9DE',
    '110': '#FFE2E6',
    '120': '#FFECEE',
    '130': '#FFF5F7',
    '140': '#FFFFFF',
  },
  green: {
    '0': '#0A180E',
    '10': '#112A18',
    '20': '#16361E',
    '30': '#1B4225',
    '40': '#22542F',
    '50': '#296639',
    '60': '#307844',
    '70': '#41A25B',
    '80': '#53BA6F',
    '90': '#B7E2C2',
    '100': '#DBF0E1',
    '110': '#E4F4E8',
    '120': '#EDF8F0',
    '130': '#F6FBF7',
    '140': '#FFFFFF',
  },
  orange: {
    '0': '#260F00',
    '10': '#3A1700',
    '20': '#4D1E00',
    '30': '#602600',
    '40': '#7D3100',
    '50': '#9A3D00',
    '60': '#B74800',
    '70': '#F15F00',
    '80': '#FF802E',
    '90': '#FFCAA7',
    '100': '#FFE4D3',
    '110': '#FFEBDE',
    '120': '#FFF2E9',
    '130': '#FFF8F4',
    '140': '#FFFFFF',
  },
  lightOrange: {
    '0': '#1B1300',
    '10': '#2F2100',
    '20': '#3D2B00',
    '30': '#4A3400',
    '40': '#654700',
    '50': '#7A5500',
    '60': '#8E6300',
    '70': '#BD8400',
    '80': '#D89700',
    '90': '#FFD573',
    '100': '#FFE7AF',
    '110': '#FFEDC3',
    '120': '#FFF3D7',
    '130': '#FFF9EB',
    '140': '#FFFFFF',
  },
  magenta: {
    '0': '#2B062B',
    '10': '#3D083D',
    '20': '#570B57',
    '30': '#680D68',
    '40': '#821182',
    '50': '#9C149C',
    '60': '#B717B7',
    '70': '#E743E7',
    '80': '#ED72ED',
    '90': '#F8C7F8',
    '100': '#FAD9FA',
    '110': '#FBE3FB',
    '120': '#FDECFD',
    '130': '#FEF6FE',
    '140': '#FFFFFF',
  },
  purple: {
    '0': '#22093A',
    '10': '#2F0D51',
    '20': '#431274',
    '30': '#51168C',
    '40': '#651BAE',
    '50': '#7921D1',
    '60': '#8D3AE0',
    '70': '#AF74E9',
    '80': '#BE8FED',
    '90': '#E4D1F8',
    '100': '#F0E5FB',
    '110': '#F4EBFC',
    '120': '#F7F2FD',
    '130': '#FBF8FE',
    '140': '#FFFFFF',
  },
};

export default color;
