/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { PropsWithChildren } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import ExtensionIcon from '@material-ui/icons/Extension';
import TuneIcon from '@material-ui/icons/Tune';
import DomainIcon from '@material-ui/icons/Domain';
import PieChartIcon from '@material-ui/icons/PieChart';
import AttachMoney from '@material-ui/icons/AttachMoney';
import LinkIcon from '@material-ui/icons/Link';
import MenuBook from '@material-ui/icons/MenuBook';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MarkunreadMailbox from '@material-ui/icons/MarkunreadMailbox';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CloudIcon from '@material-ui/icons/Cloud';
import Security from '@material-ui/icons/Security';
import ComputerIcon from '@material-ui/icons/Computer';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import RouterIcon from '@material-ui/icons/Router';
import SettingsIcon from '@material-ui/icons/Settings';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import TimelineIcon from '@material-ui/icons/Timeline';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import HighlightIcon from '@material-ui/icons/Highlight';
import LockIcon from '@material-ui/icons/Lock';
import { useApi, configApiRef, useApp } from '@backstage/core-plugin-api';
import {
  Sidebar,
  SidebarPage,
  SidebarItem,
  SidebarSpace,
  SidebarGroup,
  SidebarExpandButton,
  useSidebarOpenState,
  Link,
  SidebarSubmenu,
  SidebarSubmenuItem,
  useSidebarPinState,
} from '@backstage/core-components';
import { Settings as SidebarSettings } from '@backstage/plugin-user-settings';
import { Shortcuts } from '@backstage-community/plugin-shortcuts';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import { SidebarSearchModal } from '@backstage/plugin-search';
import PolicyIcon from '@material-ui/icons/Policy';
import { ADMIN_TOGGLE_MAINTENANCE_MODE } from '../../featureFlags';
import { AdminToggled } from '@runway/devkit';

const useSidebarLogoStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    alignSelf: 'stretch',
    padding: 20,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();

  return (
    <Link to="/" underline="none" className={classes.root} aria-label="Home">
      <img height="32" alt="Runway" src="/runway_logo2.svg" />
    </Link>
  );
};

const useDividerStyles = makeStyles(theme => ({
  divider: {
    width: '100%',
    borderStyle: 'solid',
    borderColor: theme.palette.border,
    borderWidth: '0 0 1px',
  },
}));

const Divider: React.FC = () => {
  const classes = useDividerStyles();

  return <hr className={classes.divider} />;
};

const useRootStyles = makeStyles(theme => ({
  versionNumber: {
    alignSelf: 'stretch',
    textAlign: 'center',
    color: theme.palette.navigation.color,
  },
  homeNavItemIndicatorInactive: {
    '&&': {
      borderColor: 'transparent',
      color: theme.palette.navigation.color,
    },
  },
}));

const SidebarItemWithTooltip = (props: any) => {
  const { isOpen } = useSidebarOpenState();
  const { isPinned } = useSidebarPinState();

  if (!isOpen && !isPinned) {
    // SidebarItem is wrapped in a div because of an unexpected dependency on the Tooltip requiring
    // the SidebarItem "to=" prop to be declared in order to display information
    return (
      <Tooltip title={props.text} placement="right" arrow>
        <div>
          <SidebarItem {...props} />
        </div>
      </Tooltip>
    );
  }
  return <SidebarItem {...props} />;
};

export const Root = ({ children }: PropsWithChildren) => {
  const classes = useRootStyles();
  const config = useApi(configApiRef);
  const version = config.getOptionalString('app.version') ?? '0.0.0';
  const formattedVersion = version.startsWith('pr') ? version : `v${version}`;

  return (
    <AdminToggled toggleName={ADMIN_TOGGLE_MAINTENANCE_MODE}>
      <SidebarPage>
        <Sidebar disableExpandOnHover>
          <SidebarLogo />
          <a href="https://github.com/AAInternal/runway/releases" className={classes.versionNumber} target="_blank">
            {formattedVersion}
          </a>
          <SidebarGroup label="Catalog/Create" icon={<MenuBook />} priority={4}>
            <SidebarSearchModal />
            <SidebarItemWithTooltip icon={HomeOutlinedIcon} to="/" text="Home" />
            <Divider />
            <SidebarItemWithTooltip icon={MenuBook} to="catalog" text="Catalog" />
            <AdminToggled.HideOnEnabled>
              <SidebarItemWithTooltip
                icon={CreateComponentIcon}
                to="create?filters%5Btags%5D=certified"
                text="Create/Import"
              />
            </AdminToggled.HideOnEnabled>
          </SidebarGroup>
          <SidebarGroup label="Documentation" icon={<LibraryBooks />} priority={3}>
            <SidebarItem icon={LibraryBooks} text="Docs">
              <SidebarSubmenu title="Docs">
                <SidebarSubmenuItem title="Community Docs" icon={LibraryBooks} to="/docs" />
                <Divider />
                <SidebarSubmenuItem
                  title="Runway Docs"
                  icon={useApp().getSystemIcon('runway')}
                  to="/docs/default/component/runway"
                />
                <SidebarSubmenuItem
                  title="DevOps (DTE) Docs"
                  icon={AllInclusiveIcon}
                  to="/docs/default/component/devops-tech-enablement"
                />
                <SidebarSubmenuItem title="App Migration" icon={CloudIcon} to="/migration-guidance" />
                <SidebarSubmenuItem title="Tech Radar" icon={TrackChangesIcon} to="/docs/default/component/techradar" />
                <SidebarSubmenuItem
                  title="Policy Center"
                  icon={Security}
                  to="/docs/default/component/IT-Policies-and-Standards"
                />
                <SidebarSubmenuItem
                  title="Tech Strategy and Governance (TSG)"
                  icon={LibraryBooks}
                  to="/docs/default/component/Tech-Strategy-and-Governance"
                />
                <SidebarSubmenuItem
                  title="Knowledge Management"
                  icon={LibraryBooks}
                  to="/docs/default/component/kt-enterprise-it"
                />
              </SidebarSubmenu>
            </SidebarItem>
          </SidebarGroup>
          <SidebarGroup label="Self Service" icon={<ExtensionIcon />} priority={2}>
            <AdminToggled.HideOnEnabled>
              <SidebarItem icon={DomainIcon} text="Infrastructure">
                <SidebarSubmenu title="Infrastructure">
                  <SidebarSubmenuItem title="Kubernetes" icon={ViewModuleIcon} to="/infrastructure" />
                  <SidebarSubmenuItem title="Cloud" icon={CloudIcon} to="/infrastructure/cloud" />
                  <SidebarSubmenuItem title="VMs" icon={ComputerIcon} to="/infrastructure/vms" />
                  <SidebarSubmenuItem title="API Management" icon={TuneIcon} to="/apigee/create" />
                  <SidebarSubmenuItem title="Cost Management" icon={AttachMoney} to="/cost-management" />
                  <SidebarSubmenuItem title="Networking" icon={RouterIcon} to="/project42" />
                  <SidebarSubmenuItem title="Secret Vault" icon={LockIcon} to="/secret-vault" />
                </SidebarSubmenu>
              </SidebarItem>
              <SidebarItem icon={TimelineIcon} text="Data Management">
                <SidebarSubmenu title="Data Management">
                  <SidebarSubmenuItem title="Data &amp; Analytics" icon={DataUsageIcon} to="/orion" />
                  <SidebarSubmenuItem title="Enterprise MQ" icon={MarkunreadMailbox} to="/emq" />
                  <SidebarSubmenuItem title="EMFT" icon={FileCopyIcon} to="/emft" />
                  <SidebarSubmenuItem title="GraphQL" icon={PieChartIcon} to="/graphql" />
                  <SidebarSubmenuItem title="Opshub" icon={ViewModuleIcon} to="/opshub" />
                </SidebarSubmenu>
              </SidebarItem>
            </AdminToggled.HideOnEnabled>
            <SidebarItemWithTooltip icon={LockOpenIcon} to="/access" text="Access Control" />
            <SidebarItem icon={TransferWithinAStationIcon} text="Community Tools">
              <SidebarSubmenu title="Community Tools">
                <SidebarSubmenuItem title="Usermap" icon={FingerprintIcon} to="/usermap" />
                <SidebarSubmenuItem title="Dashboards" icon={EqualizerIcon} to="/dash" />
                <SidebarSubmenuItem title="EZ URLs" icon={LinkIcon} to="/ezurlshortener" />
                <AdminToggled.HideOnEnabled>
                  <SidebarSubmenuItem title="DevOps" icon={AllInclusiveIcon} to="/devops" />
                </AdminToggled.HideOnEnabled>
                <SidebarSubmenuItem title="Swagger Editor" icon={BorderColorIcon} to="/swagger" />
                <AdminToggled.HideOnEnabled>
                  <SidebarSubmenuItem title="Monitoring" icon={PolicyIcon} to="/apm" />
                  <SidebarSubmenuItem title="Tower" icon={HighlightIcon} to="/tower" />
                </AdminToggled.HideOnEnabled>
                <SidebarSubmenuItem title="Apollo Explorer" icon={LinkIcon} to="/apollo-explorer" />
              </SidebarSubmenu>
            </SidebarItem>
          </SidebarGroup>
          <Divider />
          <AdminToggled.HideOnEnabled>
            <Shortcuts />
            <Divider />
          </AdminToggled.HideOnEnabled>
          <SidebarSpace />
          <SidebarGroup label="Settings" icon={<SettingsIcon />} to="/settings" priority={1}>
            <SidebarSettings />
          </SidebarGroup>
          <SidebarExpandButton />
        </Sidebar>
        {children}
      </SidebarPage>
    </AdminToggled>
  );
};
