import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { ObjectLike, RowProps } from '../types';
import { componentGridStyle } from '../styles';
import { useWatch } from 'react-hook-form';
import FormContext from '../FormContext';

/**
 * Use this component to encapsulate 1 or more children components in a single row.
 * @param spacing optional spacing between items
 * @param children children components
 * @param options grid prop options
 * @param title title for row can be component or string
 * @param display function to determine if the row should be displayed based on form input values
 */
export function FormRow<T extends ObjectLike>({
  children,
  spacing = 2,
  options = {},
  title,
  display = () => true,
}: RowProps<T>) {
  const { ctx } = useContext(FormContext);
  const { control } = ctx;
  const displayFormRow = display(useWatch({ control }));

  return (
    <>
      {displayFormRow && (
        <>
          {title && (
            <Box role="heading" display="flex" alignItems="center" style={componentGridStyle}>
              {title}
            </Box>
          )}
          <Grid aria-label="form-row" container spacing={spacing} direction="row" {...{ ...options }}>
            {children}
          </Grid>
        </>
      )}
    </>
  );
}
